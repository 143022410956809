html {
  scroll-behavior: smooth;
 }

.morph {
  border-radius: 50px;
  background: #ffffff;
  box-shadow:  20px 20px 60px #d9d9d9,
               -20px -20px 60px #ffffff;

}